<template>
  <base-section
    id="partners"
  >
    <base-section-heading>
      <span
        style="font-size: 20px; color: grey;"
        class="font-weight-thin"
      >
        Join our partner program
      </span>
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="4"
        >
          <base-avatar-card
            v-bind="feature"
            align="center"
            horizontal
          >
            This clould be your logo.
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row
      justify="center"
      class="mt-4"
    >
      <v-btn
        rounded
        color="primary"
        min-width="200"
      >
        Contact us
      </v-btn>
    </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Partner',
          icon: 'mdi-account-tie',
        },
        {
          title: 'Partner',
          icon: 'mdi-account-tie',
        },
        {
          title: 'Partner',
          icon: 'mdi-account-tie',
        },
      ],
    }),
  }
</script>
